import { Format } from "./format";

/**
 * Diferença de tempo entre `data` e hoje em dias, meses ou anos
 */
export const tempoDesde = (data: Date) => {
    const hoje = new Date();
    const diffMs = hoje.getTime() - data.getTime();
    const difDias = Math.ceil(diffMs / (1000 * 60 * 60 * 24));
    const difMeses = Math.floor(difDias / 30);
    const difAnos = Math.floor(difMeses / 12);
    if (difAnos > 0) {
        return `${difAnos} ano` + (difAnos > 1 ? "s" : "");
    }
    if (difMeses > 0) {
        return `${difMeses} ` + (difMeses > 1 ? "meses" : "mês");
    }
    return `${difDias} dia` + (difDias > 1 ? "s" : "");
};

export const getTimeHHmm = (date: Date) => date.toLocaleTimeString("pt-Br", { hour: "2-digit", minute: "2-digit" });

export const dateToDDMMYYYY = (date: Date) => date.toLocaleDateString("pt-BR");

export const formatDateAndTime = (date: Date) => `${dateToDDMMYYYY(date)} ${getTimeHHmm(date)}`

export const dateToMMMYYYY = (date: Date) => {
    const month = date.toLocaleDateString("pt-BR", { month: "short" });
    const year = date.toLocaleDateString("pt-BR", { year: "numeric" });
    return `${month}/${year}`;
};
export const dateToMMYYYY = (date: Date) => {
    const month = date.toLocaleDateString("pt-BR", { month: "numeric" });
    const year = date.toLocaleDateString("pt-BR", { year: "numeric" });
    return `${month}/${year}`;
};
export const dateToFullMonth = (date: Date) => date.toLocaleDateString("pt-BR", { month: "long" });

export const dateDiff = (date1: Date, date2: Date) => new Duration(date1.getTime() - date2.getTime());

export class Duration {
    ms: number;
    constructor(timeSpan: string | Duration | number) {
        switch (typeof timeSpan) {
            case "string":
                const [h, m, s] = timeSpan.split(":");
                this.ms = ((parseInt(h) * 60 + parseInt(m)) * 60 + parseFloat(s)) * 1000;
                break;
            case "number":
                this.ms = timeSpan;
                break;
            default:
                this.ms = timeSpan.milliseconds;
        }
    }
    format() {
        let s = Math.round(this.ms / 1000);
        let m = Math.floor(s / 60);
        s -= m * 60;
        const h = Math.floor(m / 60);
        m -= h * 60;
        return `${Format.toFixedInteger(h, 2)}:${Format.toFixedInteger(m, 2)}:${Format.toFixedInteger(s, 2)}`;
    }
    asHours() {
        const s = Math.round(this.ms / 1000);
        const m = Math.floor(s / 60);
        const h = Math.round(m / 60);
        return h;
    }
    formatHoras() {
        const h = this.asHours();
        return `${h} ${h !== 1 ? "horas" : "hora"}`;
    }
    static add(first: Duration, second: Duration): Duration {
        return new Duration(first.milliseconds + second.milliseconds);
    }
    get milliseconds() {
        return this.ms;
    }
}
