import classNames from "classnames";
import { useField } from "formik";
import React from "react";
import styles from "../../field.module.scss";
import { Mask, maskString, unmaskString } from "./mask";

export interface OmnijusTextFieldProps {
    name: string;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    masks?: Array<Mask>;
    className?: string;
    keepMask?: boolean;
}

export const OmnijusTextField = (props: OmnijusTextFieldProps) => {
    const [field, meta, helpers] = useField<string>(props.name);

    return (
        <div className={classNames([styles["field-control"], props.className])}>
            {props.label ? (
                <label className={styles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            <input
                {...field}
                name={props.name}
                placeholder={props.placeholder}
                value={
                    (props.keepMask ?
                        field.value
                        : props.masks ?
                            maskString(field.value, props.masks)
                            : field.value
                    ) || ""
                }
                onChange={(e) => {
                    let aux = props.masks ?
                        props.keepMask ?
                            maskString(e.target.value, props.masks)
                            : unmaskString(e.target.value, props.masks)
                        : e.target.value;
                    helpers.setValue(aux);
                }}
                disabled={props.disabled}
                className={classNames(styles["input"], { [styles["input-error"]]: meta.touched && meta.error })}
            />
            <p className={styles["error"]}>{meta.touched && meta.error ? meta.error : ""}</p>
        </div>
    );
};
