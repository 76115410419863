import React, { MouseEventHandler } from "react";
import { PropsWithChildren } from "react";
import styles from "./text-button.module.scss";

interface TextButtonProps {
    onClick?: MouseEventHandler<HTMLButtonElement>; 
    disabled?: boolean;
    className?: string;
}

export const TextButton = (props: PropsWithChildren<TextButtonProps>) => (
    <button type="button" className={`${styles["btn-text"]} ${props.className}`} onClick={props.onClick} disabled={props.disabled}>
        {props.children}
    </button>
);
