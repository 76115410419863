import React, { useEffect, useState } from "react";
import { ReactComponent as LoadingIcon } from "./loading.svg";
import styles from "./loading.module.scss";
import classNames from "classnames";
import { OmnijusResponseException } from 'lib/request-api';
import { ActionFeedback } from 'lib/action-feedback';

export interface LoadingProps<T> {
    promise?: Promise<T>;
    children: (v?: T) => React.ReactNode;
    explicitReload?: boolean;
    inline?: boolean;
    className?: string;
}

export function Loading<T>(props: LoadingProps<T>) {
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [value, setValue] = useState<T>();

    const className = classNames([styles.loading, { [styles.loadingInline]: props.inline }], props.className, {
        [styles.errorMessage]: errorMessage,
    });

    useEffect(() => {
        let mounted = true;
        if (props.promise !== undefined) {
            if (props.explicitReload || props.explicitReload === undefined) {
                setIsLoading(true);
            }

            setErrorMessage(null);

            props.promise
                .then((v) => mounted && setValue(v))
                .catch((err) => {
                    if (err instanceof OmnijusResponseException) {
                        if (err.status == 401) {
                            ActionFeedback.error({
                                icon: 'warning',
                                title: 'Seu login expirou!',
                                footer: 'Ao pressionar ok, você será redirecionado para a página de login.',
                            }).then(() => {
                                window.location.reload();
                            });
                        }
                    }
                    return mounted && setErrorMessage(err.toString());
                })
                .finally(() => mounted && setIsLoading(false));
        }
        return () => {
            mounted = false;
        };
    }, [props.promise, props.explicitReload]);

    if (errorMessage) {
        return <div className={className}>{errorMessage}</div>;
    }

    return isLoading ? (
        <div className={className}>
            <LoadingIcon title="Carregando" />
        </div>
    ) : (
        <>{props.children(value)}</>
    );
}
