import classNames from "classnames";
import { Field, FieldProps } from "formik";
import React from "react";
import styles from "../../field.module.scss";

export interface OmnijusTextAreaFieldProps {
    name: string;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
}

export const OmnijusTextAreaField = (props: OmnijusTextAreaFieldProps) => (
    <Field name={props.name} id={props.name} placeholder={props.placeholder}>
        {({ field, meta }: FieldProps) => (
            <div className={classNames([styles["field-control"], props.className])}>
                {props.label ? (
                    <label className="label" htmlFor={props.name}>
                        {props.label}
                    </label>
                ) : null}
                <textarea
                    id={props.name}
                    {...field}
                    disabled={props.disabled}
                    className={classNames([styles["input"], { ["input-error"]: meta.touched && meta.error }])}
                    placeholder={props.placeholder}
                />
                <div className={styles["error"]}>{meta.touched && meta.error ? meta.error : ""}</div>
            </div>
        )}
    </Field>
);
