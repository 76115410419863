import { Field, FieldProps, useFormikContext } from "formik";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fieldStyles from "../../field.module.scss";

export interface OmnijusRangeDateFieldProps {
    label?: string;
    nameDe: string;
    nameAte: string;
    placeholderDe?: string;
    placeholderAte?: string;
    className?: string;
}

export const OmnijusRangeDateField = (props: OmnijusRangeDateFieldProps) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const placeHolderDe = props.placeholderDe || "--/--/----";
    const placeholderAte = props.placeholderAte || "--/--/----";

    return (
        <div className={fieldStyles["field-control"] + (" " + props.className || "")}>
            {props.label ? (
                <label className={fieldStyles["label"]} htmlFor={props.nameDe}>
                    {props.label}
                </label>
            ) : null}
            <div className={fieldStyles["field-wrapper"]}>
                <Field name={props.nameDe} id={props.nameDe} placeholder={placeHolderDe}>
                    {({ field, meta }: FieldProps) => (
                        <div className={fieldStyles["range"]}>
                            <div className={fieldStyles["content"]}>
                                <span
                                    className={
                                        fieldStyles["prefix"] +
                                        (meta.touched && meta.error ? " " + fieldStyles["prefix-error"] : "")
                                    }
                                >
                                    de
                                </span>
                                <ReactDatePicker
                                    autoComplete="off"
                                    isClearable={true}
                                    selected={field.value}
                                    onChange={(value) => {
                                        setFieldTouched(field.name, true);
                                        setFieldValue(field.name, value || undefined);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    className={
                                        fieldStyles["input"] +
                                        (meta.touched && meta.error ? " " + fieldStyles["input-error"] : "")
                                    }
                                    name={field.name}
                                    placeholderText={placeHolderDe}
                                    onBlur={field.onBlur}
                                />
                            </div>
                            <div className={fieldStyles["error"]}>{meta.touched && meta.error ? meta.error : ""}</div>
                        </div>
                    )}
                </Field>
                <Field name={props.nameAte} id={props.nameAte} placeholder={placeholderAte}>
                    {({ field, meta }: FieldProps) => (
                        <div className={fieldStyles["range"]}>
                            <div className={fieldStyles["content"]}>
                                <span
                                    className={
                                        fieldStyles["prefix"] +
                                        (meta.touched && meta.error ? " " + fieldStyles["prefix-error"] : "")
                                    }
                                >
                                    até
                                </span>
                                <ReactDatePicker
                                    autoComplete="off"
                                    isClearable={true}
                                    selected={field.value}
                                    onChange={(value) => {
                                        setFieldTouched(field.name, true);
                                        setFieldValue(field.name, value || undefined);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    className={
                                        fieldStyles["input"] +
                                        (meta.touched && meta.error ? " " + fieldStyles["input-error"] : "")
                                    }
                                    name={field.name}
                                    placeholderText={placeholderAte}
                                    onBlur={field.onBlur}
                                />
                            </div>
                            <div className={fieldStyles["error"]}>{meta.touched && meta.error ? meta.error : ""}</div>
                        </div>
                    )}
                </Field>
            </div>
        </div>
    );
};
