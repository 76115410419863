import { format, parseISO } from "date-fns";
import { Format } from "./format";

const listaTiposParametros = ["string", "boolean", "number", "Date"] as const;
type NomeTipoParametro = typeof listaTiposParametros[number];
export type NomesTiposParametros<T extends object> = { [key in keyof T]: NomeTipoParametro };
export type TipoParametro = string | boolean | number | Date;

export class OmnijusResponseException {
    businessException = false;
    networkException = false;
    status = 0;
    message: string = 'Ocorreu um erro inesperado';
    errors?: Record<string, Array<string>>

    constructor(public response: Response | null, public responseText?: string) {
        try {

            if (response) {
                const json = responseText ? JSON.parse(responseText) : {
                    title: "Ocorreu um erro inesperado" + (response.statusText ? ` (${response.statusText})` : "")
                };
                this.status = response.status;

                switch (response.status) {
                    case 400:
                    case 422:
                        this.message = json.title;
                        this.businessException = true;
                        this.errors = json.errors;
                        break;
                    case 401:
                        this.message = 'Seu login expirou!';
                        break;
                    case 403:
                        this.message = 'Acesso Negado!';
                        break;
                    default:
                        this.message = json.title;
                }
            } else {
                this.message = "Verifique sua conexão com a internet." + (responseText ? ` (${responseText})` : "");
                this.networkException = true;
            }
        }
        catch (e) {
            console.error('Erro ao parsear o json da resposta', e, responseText);
            this.message = "Erro processando a resposta" + (responseText ? ` (${responseText})` : "");
        }
    }

    toString() {
        return `${this.message}`;
    }
}

export type RequestApiConfig = {
    beforeSendHeaders: (headers: Headers) => void;
}

export class RequestApi {

    constructor(private clientId: string, private config?: RequestApiConfig) {
    }

    getToken() {
        const tokenKey = "oidc.user:" + process.env.REACT_APP_AUTH_URL + ":" + this.clientId;
        const tokenStr = sessionStorage.getItem(tokenKey);
        return tokenStr ? JSON.parse(tokenStr).access_token : "";
    }

    getHeaders() {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${this.getToken()}`);

        if (this.config && this.config.beforeSendHeaders) {
            this.config.beforeSendHeaders(headers);
        }

        return headers;
    }

    async trataResponseBlob(res: Response, formatError?: (res: Response, json?: any) => object) {
        if (res.ok) {
            try {
                return await res.blob();
            } catch {
                return undefined;
            }
        }
        return await this.trataResponse(res, formatError)
    }

    async trataResponse(res: Response, formatError?: (res: Response, json?: any) => object) {
        const responseText = await res.text();

        if (res.ok) {
            try {
                return JSON.parse(responseText);
            } catch {
                return undefined;
            }
        }

        if (formatError) {
            let json;
            try {
                json = JSON.parse(responseText);
            } catch (e) {
                throw formatError(res, responseText);
            }

            if (json) {
                throw formatError(res, json);
            }
        }

        throw new OmnijusResponseException(res, responseText);
    }

    public post<T>(
        url: string,
        body?: object,
        formatError?: (res: Response, json?: any) => object
    ): Promise<T | undefined> {
        return this.fetchMethod("POST", url, body, formatError);
    }

    public patch<T>(
        url: string,
        body?: object,
        formatError?: (res: Response, json?: any) => object
    ): Promise<T | undefined> {
        return this.fetchMethod("PATCH", url, body, formatError);
    }

    public put<T>(
        url: string,
        body?: any,
        formatError?: (res: Response, json?: any) => object
    ): Promise<T | undefined> {
        return this.fetchMethod("PUT", url, body, formatError);
    }

    public async fetchFormdata<T>(
        method: string,
        url: string,
        data: any,
        formatError?: (res: Response, json?: any) => object
    ): Promise<T | undefined> {
        const formData = getFormData(data);

        try {
            const res = await fetch(url, {
                method: method,
                body: formData,
                headers: this.getHeaders(),
            });

            return this.trataResponse(res, formatError);
        } catch (err) {
            if(err instanceof  OmnijusResponseException){
                throw err;
            }
            throw new OmnijusResponseException(null, err.toString());
        }
    }

    public async postFormdata<T>(
        url: string,
        data: any,
        formatError?: (res: Response, json?: any) => object
    ): Promise<T | undefined> {
        return this.fetchFormdata("POST", url, data, formatError);
    }

    public async putFormdata<T>(
        url: string,
        data: any,
        formatError?: (res: Response, json?: any) => object
    ): Promise<T | undefined> {
        return this.fetchFormdata("PUT", url, data, formatError);
    }

    public get<T>(url: string, formatError?: (res: Response, json?: any) => object): Promise<T | undefined> {
        return this.fetchMethod("GET", url, undefined, formatError);
    }

    public delete<T>(url: string, formatError?: (res: Response, json?: any) => object): Promise<T | undefined> {
        return this.fetchMethod("DELETE", url, undefined, formatError);
    }

    public async fetchMethod<T>(
        method: string,
        url: string,
        body?: object,
        formatError?: (res: Response, json?: any) => object
    ): Promise<T | undefined> {
        const headers = this.getHeaders();

        if (body && !headers.has('Content-Type')) {
            headers.append("Content-Type", "application/json");
        }

        try {
            const res = await fetch(url, {
                method: method,
                body: body ? JSON.stringify(body) : undefined,
                headers: headers,
            });

            return this.trataResponse(res, formatError);
        } catch (err) {
            throw new OmnijusResponseException(null, err.toString());
        }
    }

    public static objectToQueryString(obj: object) {
        const query = new URLSearchParams();

        Object.entries(obj).forEach((e) => {
            if (e[1] !== undefined && e[1] !== null && e[1] !== "") {
                let value: string;
                if (e[1] instanceof Date) {
                    value = format(e[1], "yyyy-MM-dd");
                } else if (typeof e[1] === "object") {
                    value = JSON.stringify(e[1]);
                } else {
                    value = e[1];
                }
                query.set(e[0], value);
            }
        });

        return query.toString();
    }

    public static parseQueryString<T extends Record<string, TipoParametro>>(
        queryString: string,
        types: NomesTiposParametros<T>
    ) {
        const query = new URLSearchParams(queryString);
        const r: Record<string, TipoParametro | undefined> = {};
        query.forEach((val, key) => {
            if (key in types) {
                switch (types[key as keyof T]) {
                    case "string":
                        r[key] = val;
                        break;
                    case "boolean":
                        const b = Format.strToBoolean(val);
                        if (b !== undefined) {
                            r[key] = b;
                        }
                        break;
                    case "number":
                        const n = parseInt(val);
                        if (!isNaN(n)) {
                            r[key] = n;
                        }
                        break;
                    case "Date":
                        const d = parseISO(val);
                        if (!isNaN(d.getTime())) {
                            r[key] = d;
                        }
                }
            }
        });
        return r;
    }

    public async getBlob(url: string, formatError?: (res: Response, json?: any) => object) {
        const res = await fetch(url, {
            method: "GET",
            headers: this.getHeaders(),
        });

        return this.trataResponseBlob(res, formatError);
    }

    public static async downloadBlob(blob: Blob, filename: string) {
        const urlBlob = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = urlBlob;
        link.setAttribute("download", filename);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode?.removeChild(link);
    }

    public async download(url: string, filename: string) {
        const blob = await this.getBlob(url);

        await RequestApi.downloadBlob(blob, filename);
    }

    public static async downloadExternal(url: string, filename: string) {
        const res = await fetch(url);

        const blob = await res.blob();

        await RequestApi.downloadBlob(blob, filename);
    }

    toDate(str?: string | Date) {
        return str ? new Date(str) : undefined;
    }

    public async patchMultipart<T>(
        url: string,
        data: any,
        formatError?: (res: Response, json?: any) => object
    ): Promise<T | undefined> {
        const formData = getFormData(data);
        const res = await fetch(url, {
            method: "PATCH",
            body: formData,
            headers: this.getHeaders(),
        });

        return this.trataResponse(res, formatError);
    }
}

const getFormData = (data: any) => {
    const formData = new FormData();

    for (const key in data) {
        if (data[key] instanceof FileList) {
            const files = Array.from(data[key]) as File[];

            files.forEach((file) => {
                formData.append(key, file);
            });
        } else if (Array.isArray(data[key])) {
            Array.from(data[key]).forEach((item) => {
                formData.append(key, item as any);
            });
        } else {
            formData.append(key, data[key]);
        }
    }

    return formData;
};
