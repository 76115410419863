import { useField } from "formik";
import React, { useContext, useEffect } from "react";
import Select from "react-select";
import styles from "../../field.module.scss";
import { ValoresComboContext } from "../../valores-combo-context";

export interface OmnijusSelectFieldProps {
    name: string;
    label?: string;
    options?: Array<SelectObject>;
    isMulti?: boolean;
    isClearable?: boolean;
    isDisabled?: boolean;
    className?: string;
    onChange?: (o: SelectObject | SelectObject[]) => void;
    getValue?: () => string | string[];
}

export interface SelectObject {
    label: string;
    value: string;
}

export const OmnijusSelectField = (props: OmnijusSelectFieldProps) => {
    const valoresComboContext = useContext(ValoresComboContext);
    useEffect(() => {
        if (props.options && !valoresComboContext.valoresCombos[props.name]?.idValue) {
            const options: Record<string, string> = {};
            props.options.forEach((option) => (options[option.value] = option.label));
            valoresComboContext.setValoresCombos({
                ...valoresComboContext.valoresCombos,
                [props.name]: { idValue: options, label: valoresComboContext.valoresCombos[props.name]?.label },
            });
        }
    }, [props.name, props.options, valoresComboContext]);
    const [field, meta, helpers] = useField({ multiple: props.isMulti, name: props.name });

    return (
        <div className={`${styles["field-control"]} ${props.className || ""}`}>
            {props.label ? (
                <label className={styles["label"]} htmlFor={props.name}>
                    {props.label}
                </label>
            ) : null}
            <Select
                isDisabled={props.isDisabled}
                options={props.options}
                isMulti={props.isMulti}
                isClearable={props.isClearable === undefined ? true : props.isClearable}
                value={props.options?.filter((o) => {
                    if (props.isMulti && field.value) {
                        const aux = props.getValue
                            ? (props.getValue() as string[])
                            : ((field.value as any[]).map((a) => String(a)) as string[]);
                        return aux?.find((v) => v === o.value);
                    } else {
                        const aux = props.getValue ? props.getValue() : String(field.value);
                        return o.value === aux;
                    }
                })}
                onChange={(e) => {
                    if (props.isMulti) {
                        const aux = e as SelectObject[];
                        helpers.setTouched(true);
                        if (props.onChange) {
                            props.onChange(aux);
                        } else {
                            helpers.setValue(aux?.map((a) => a.value));
                        }
                    } else {
                        const aux = e as SelectObject;
                        if (props.onChange) {
                            props.onChange(aux);
                        } else {
                            setTimeout(() => {
                                helpers.setValue(aux?.value || "");
                                helpers.setTouched(true);
                            }, 0);
                        }
                    }
                }}
            />
            {meta.touched && meta.error ? (
                <p className={styles["error"]}>
                    {typeof meta.error === "object" ? (Object.values(meta.error)[0] as string) : meta.error}
                </p>
            ) : null}
        </div>
    );
};
