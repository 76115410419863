import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { OmnijusResponseException } from "./request-api";
import styles from "./action-feedback.module.scss";

interface InfoConfiguration {
    title?: string;
    text?: string;
}

export const ActionFeedback = {
    processing: async <T>(configuration: {
        title: string;
        execution: Promise<T>;
        onError?: (error?: any) => Promise<any>;
    }) => {
        let res = await Swal.fire<T>({
            title: configuration.title,
            showConfirmButton: false,
            allowOutsideClick: false,
            willOpen: async () => {
                Swal.showLoading();
                try {
                    const result = {
                        isConfirmed: true,
                        isDismissed: false,
                        isDenied: false,
                        value: await configuration.execution,
                    };
                    Swal.close(result);
                } catch (err: any) {
                    if (configuration.onError) {
                        await configuration.onError(err);
                    } else if (err instanceof OmnijusResponseException) {
                        const errors =
                            err.errors && Object.entries(err.errors).length > 0 ? Object.entries(err.errors) : null;
                        await ActionFeedback.error({
                            icon: err.businessException ? "warning" : "error",
                            title: err.businessException ? "Ocorreu um erro de validação" : err.message,
                            text: errors ? undefined : err.message,
                            width: 600,
                            html: errors
                                ? `
                                    <dl class="${styles.erros}">
                                        ${errors
                                            .map(([key, values]) => `<p><strong>${key}</strong>${values.join("; ")}`)
                                            .join("")}
                                    </dl>`
                                : undefined,
                        });

                        if (err.status === 401) {
                            //Caso o login tenha expirado, vai para a home ou login
                            window.location.reload();
                        }
                    } else {
                        await ActionFeedback.error({
                            icon: "error",
                            title: "Oops...",
                            text: err.message,
                            footer: err.message || err.toString(),
                        });
                    }
                }
            },
        });
        return res.value;
    },
    show: async (configuration: SweetAlertOptions) => {
        return await Swal.fire(configuration);
    },
    error: async (configuration: SweetAlertOptions) => {
        return await Swal.fire({
            icon: "error",
            confirmButtonText: "Ok",
            ...configuration,
        });
    },
    info: async (configuration: InfoConfiguration) => {
        return await Swal.fire({
            icon: "info",
            confirmButtonText: "Ok",
            ...configuration,
        });
    },
    warning: (configuration: SweetAlertOptions) => {
        return Swal.fire({
            icon: "warning",
            allowOutsideClick: false,
            ...configuration,
        });
    },
    confirm: async (title: string, confirmText?: string, cancelText?: string, text?: string, icon?: SweetAlertIcon) => {
        const result = await Swal.fire({
            title: title,
            icon: icon || "question",
            text: text,
            confirmButtonText: confirmText || "OK",
            cancelButtonText: cancelText || "Cancelar",
            showCancelButton: true,
        });
        return result.isConfirmed;
    },
};
