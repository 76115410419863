import { RequestApi } from "lib/request-api";
import { FuncionalidadeAcessoUser } from "./models/funcionalidade-acesso-user";

export const HEADER_CLIENTE_ID = "CLIENTE-ID";
export const HEADER_ESCRITORIO_ID = "ESCRITORIO-ID";

export interface ControleAcessoExtras {
    idCliente?: string;
    idEscritorio?: string;
}

export const listarAcessos = async (clientId: string, baseUrl: string, extras?: ControleAcessoExtras) => {
    const request = new RequestApi(clientId, {
        beforeSendHeaders: (headers) => {
            if (extras?.idCliente) {
                headers.append(HEADER_CLIENTE_ID, extras.idCliente);
            }
            if (extras?.idEscritorio) {
                headers.append(HEADER_ESCRITORIO_ID, extras.idEscritorio);
            }
        },
    });
    const url = `${baseUrl}/ControleAcesso/v1/Acessos`;
    return (await request.get<FuncionalidadeAcessoUser[]>(url)) || [];
};
