import { Formik, FormikProps } from "formik";
import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styles from "./modal-form.module.scss";

const MySwal = withReactContent(Swal);

interface ModalHelpers<T> {
    close: (result: T | undefined) => void;
}

export const showModalForm = <T extends object>(
    title: string,
    initialValues: T,
    form: ((formikProps: FormikProps<T>, modalHelpers: ModalHelpers<T>) => React.ReactNode) | React.ReactNode,
    width?: string,
    validationSchema?: any | (() => any)
) => {
    return new Promise<T | undefined>((resolve, reject) => {
        MySwal.fire({
            title,
            width,
            html: (
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        resolve(values);
                        MySwal.close();
                    }}
                    validationSchema={validationSchema}
                >
                    {typeof form === "function"
                        ? (formikProps) =>
                              form(formikProps, {
                                  close: (result) => {
                                      resolve(result);
                                      MySwal.close();
                                  },
                              })
                        : form}
                </Formik>
            ),
            customClass: { container: styles.modalContainer },
            willClose: () => {
                resolve(undefined);
            },
            showConfirmButton: false,
        });
    });
};
