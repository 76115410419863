import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import styles from "./copiavel.module.scss";
import { ReactComponent as CopyIcon } from "./copy.svg";

interface CopiavelProps {
    informacaoCopiavel?: any;
    mensagem: string;
    onCopiado?: () => void;
}

export const Copiavel = (props: CopiavelProps) => {
    return (
            props.informacaoCopiavel ?
                <span className={styles["copiavel"]}>
                    <span className={styles["informacao"]}>{props.informacaoCopiavel}</span>
                    <button
                        type="button"
                        className={styles["acao"]}
                        onClick={async () => {
                            await navigator.clipboard.writeText(props.informacaoCopiavel);
                        
                            if (props.onCopiado) {
                                props.onCopiado();
                            }
                        }}
                    >
                        <CopyIcon/>
                    </button>
                </span>
                :
                null
    );
}