import ArrowDown from "assets/images/arrow-down.png";
import ArrowUp from "assets/images/arrow-up.png";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./omnijus-card.module.scss";

export interface OmnijusCardProps {
    fixed?: boolean; // Define se o body tem altura fixa ou não
    collapsable?: boolean; // Define se o card possui o comportamento de ocultar e expandir
    collapsedByDefault?: boolean; // Define se o card monta com o corpo oculto ou expandido
    header?: JSX.Element; // Elemento para aparecer no header (Não depende do body)
    body?: JSX.Element; // Elemento para aparecer no body (Não depende do header)
    collapsed?: boolean;
    onChangeCollapsed?: (collapsed: boolean) => void;
    className?: string;
    cardClassName?: string;
}

export const OmnijusCard = (props: OmnijusCardProps) => {
    const [collapsed, setCollapsed] = useState(props.collapsedByDefault);

    useEffect(() => {
        props.collapsed !== undefined && setCollapsed(props.collapsed);
    }, [props.collapsed]);

    return (
        <div className={classNames(styles["omnijus-card"], props.cardClassName)}>
            {props.header ? (
                <div className={styles["header"]}>
                    {props.header}
                    {props.collapsable ? (
                        <div className={styles["collapse"]}>
                            <button
                                className={styles["button"]}
                                onClick={() => {
                                    props.onChangeCollapsed && props.onChangeCollapsed(!collapsed);
                                    setCollapsed(!collapsed);
                                }}
                            >
                                {collapsed ? (
                                    <React.Fragment>
                                        <span className={styles["text"]}>Expandir</span>
                                        <img alt="Ícone para expandir os filtros" src={ArrowDown} />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <span className={styles["text"]}>Ocultar</span>
                                        <img alt="Ícone para ocultar os filtros" src={ArrowUp} />
                                    </React.Fragment>
                                )}
                            </button>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {props.body ? (
                <div
                    className={classNames(
                        styles["body"],
                        { [styles["body-fixed"]]: props.fixed },
                        { [styles["body-collapsable"]]: props.collapsable },
                        { [styles["collapsed"]]: props.collapsable && collapsed },
                        props.className
                    )}
                >
                    {props.body}
                </div>
            ) : null}
        </div>
    );
};
