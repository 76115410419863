import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import styles from "./card.module.scss";

export interface CardProps {
    iconSrc?: string;
    iconBackgroundClassName?: string;
    title: string | JSX.Element;
    value?: React.ReactNode;
    inline?: boolean;
    className?: string;
    headerClassName?: string;
    infoClassName?: string;
    titleClassName?: string;
    valueClassName?: string;
}

export const Card = (props: PropsWithChildren<CardProps>) => {
    return (
        <div className={classNames([props.inline ? styles["card-inline"] : styles.card, props.className])}>
            <div className={classNames([styles.header, props.headerClassName])}>
                {props.iconSrc ? (
                    <div className={classNames([styles.icon, props.iconBackgroundClassName])}>
                        <img alt={"Ícone de " + props.title} src={props.iconSrc} />
                    </div>
                ) : (
                    <></>
                )}
                <div className={classNames([styles.info, styles.infoClassName])}>
                    <div className={classNames([styles.title, styles.titleClassName])}>{props.title}</div>
                    {props?.value && (
                        <p className={classNames([styles.value, styles.valueClassName])}>{props?.value}</p>
                    )}
                </div>
            </div>
            {props?.children}
        </div>
    );
};
