import { useField, useFormikContext } from "formik";
import React from "react";
import styles from "./omnijus-range-number-field.module.scss";
import { AbstencaoOption } from "./opcao-abstencao/opcao-abstencao";

export interface OmnijusRangeNumberFieldProps {
    label?: string;
    labelAbstencaoDe?: string;
    labelAbstencaoAte?: string;
    nameDe: string;
    nameAte: string;
    nameAbstencaoDe?: string;
    nameAbstencaoAte?: string;
    placeholderDe?: string;
    placeholderAte?: string;
    className?: string;
    onMaskValue?: (value: number) => string;
    onRemoveMaskValue?: (value: string) => number;
    submitMaskedValue?: boolean;
}

export const OmnijusRangeNumberField = (props: OmnijusRangeNumberFieldProps) => {
    const [fieldDe, metaDe, helperDe] = useField(props.nameDe);
    const [fieldAte, metaAte, helperAte] = useField(props.nameAte);
    const { values } = useFormikContext<any>();

    if ((props.onMaskValue || props.submitMaskedValue) && !props.onRemoveMaskValue) {
        throw new Error("Ao informar onMaskValue ou submitMaskedValue também deve ser informado onRemoveMaskValue");
    }
    
    return (
        <div className={props.className || styles["omnijus-range-number-field"]}>
            {props.label ? (
                <label className={styles["label"]} htmlFor={props.nameDe}>
                    {props.label}
                </label>
            ) : null}
            <div className={styles["range"]}>
                <div className={styles["item"]}>
                    {props.nameAbstencaoDe ? 
                        <AbstencaoOption
                            labelAbstencao={props.labelAbstencaoDe}
                            nameAbstencao={props.nameAbstencaoDe}
                        /> : null
                    }
                    <div className={styles["input-wrapper"]}>
                        <span className={`${styles["prefix"]} ${metaDe.touched && metaDe.error ? styles["prefix-error"] : ""}`}>
                            de
                        </span>
                        <input
                            className={`${styles["input"]} ${metaDe.touched && metaDe.error ? styles["input-error"] : ""}`}
                            placeholder={props.placeholderDe || ""}
                            name={props.nameDe}
                            disabled={props.nameAbstencaoDe ? values[props.nameAbstencaoDe] : false}
                            value={props.onMaskValue ? props.onMaskValue(fieldDe.value) : fieldDe.value}
                            onChange={e => {
                                let value = e.target.value;

                                if (props.onMaskValue && props.onRemoveMaskValue) {
                                    helperDe.setValue(Number(props.onRemoveMaskValue(value).toString().substr(0, 10)));
                                } else {
                                    helperDe.setValue(Number(value));
                                }
                            }}
                        />
                    </div>
                    <div className={styles["error"]}>{metaDe.touched && metaDe.error ? metaDe.error : ""}</div>
                </div>
                <div className={styles["item"]}>
                    {props.nameAbstencaoAte ? 
                        <AbstencaoOption
                            labelAbstencao={props.labelAbstencaoAte}
                            nameAbstencao={props.nameAbstencaoAte}
                        /> : null
                    }
                    <div className={styles["input-wrapper"]}>
                        <span className={`${styles["prefix"]} ${metaDe.touched && metaDe.error ? styles["prefix-error"] : ""}`}>
                            até
                        </span>
                        <input
                            className={`${styles["input"]} ${metaDe.touched && metaDe.error ? styles["input-error"] : ""}`}
                            placeholder={props.placeholderAte || ""}
                            name={props.nameAte}
                            disabled={props.nameAbstencaoAte ? values[props.nameAbstencaoAte] : false}
                            value={props.onMaskValue ? props.onMaskValue(fieldAte.value) : fieldAte.value}
                            onChange={e => {
                                let value = e.target.value;

                                if (props.onMaskValue && props.onRemoveMaskValue) {
                                    helperAte.setValue(Number(props.onRemoveMaskValue(value).toString().substr(0, 10)));
                                } else {
                                    helperAte.setValue(Number(value));
                                }
                            }}
                        />
                    </div>
                    <div className={styles["error"]}>{metaAte.touched && metaAte.error ? metaAte.error : ""}</div>
                </div>
            </div>
        </div>
    );
};
