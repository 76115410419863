import FilterIcon from "assets/images/filter-icon.png";
import { Form, Formik } from "formik";
import React, { PropsWithChildren } from "react";
import { ButtonPrimary } from "../../buttons/button-primary/button-primary";
import styles from "./card-filtros.module.scss";
import { OmnijusCard } from "../../omnijus-card/omnijus-card";

export interface CardFiltrosProps<T> {
    onBuscar: (filtros: T) => void;
    initialValues: T;
    collapsed?: boolean;
    collapsedByDefault?: boolean;
    onChangeCollapsed?: (collapsed: boolean) => void;
    validationSchema?: any;
    botaoFiltrarInline?: boolean;
    textoBotao?: string;
}

export const CardFiltros = <T extends object>(props: PropsWithChildren<CardFiltrosProps<T>>) => {
    return (
        <div className={styles.cardFiltros}>
            <OmnijusCard
                collapsable={true}
                collapsedByDefault={props.collapsedByDefault}
                collapsed={props.collapsed}
                onChangeCollapsed={props.onChangeCollapsed}
                header={
                    <div className={styles.header}>
                        <img alt="Ícone de filtros" src={FilterIcon} />
                        <span className={styles.titulo}>Filtros</span>
                    </div>
                }
                body={
                    <div className={styles.filtrosWrapper}>
                        <Formik
                            onSubmit={props.onBuscar}
                            initialValues={props.initialValues}
                            validationSchema={props.validationSchema}
                            enableReinitialize
                        >
                            <Form className={styles.filtros}>
                                {props.children}
                                <div className={`${styles.acoes} ${props.botaoFiltrarInline ? styles.inline : ""}`}>
                                    <ButtonPrimary type="submit">{props.textoBotao || "Filtrar"}</ButtonPrimary>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                }
            />
        </div>
    );
};
