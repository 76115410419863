// import InboxIcon from "assets/images/inbox-icon.png";
// import BellIcon from "assets/images/bell-icon.png";
import { LocationState } from "history";
import React, { PropsWithChildren, ReactNode } from "react";
import { Link, NavLink, NavLinkProps } from "react-router-dom";
import { useAuth } from "../context/auth";
import { Dropdown } from "../dropdown/dropdown";
import { DownIcon } from "../icons/down-icon/down-icon";
import { OmnijusLogo } from "../omnijus-logo/omnijus-logo";
import styles from "./navigation.module.scss";

export const OmnijusNavLink = <S extends any = LocationState>(
    props: React.PropsWithoutRef<NavLinkProps<S>> & React.RefAttributes<HTMLAnchorElement>
): ReturnType<NavLink<S>> => {
    return <NavLink {...props} className={props.className || styles.navLink} activeClassName={styles.active} />;
};

export interface NavigationProps {
    logo?: ReactNode;
}

export const Navigation = ({ children, logo }: PropsWithChildren<NavigationProps>) => {
    const auth = useAuth();

    return (
        <nav className={styles.navBar}>
            <Link className={styles.navLinkDashboard} to="/">
                {logo || (
                    <div className={styles.logoOmnijus}>
                        <OmnijusLogo />
                    </div>
                )}
            </Link>
            <div className={styles.navItems}>{children}</div>
            <div className={styles.navRight}>
                {/* <div className={styles.item}>
                    <div className={styles.notification}>
                        <img alt="Ícone de mensagens" src={InboxIcon} />
                        <i className={styles.circle} />
                    </div>
                    <div className={styles.notification}>
                        <img alt="Ícone de notificações" src={BellIcon} />
                        <i className={styles.circle} />
                    </div>
                </div> */}
                <div className={styles.item}>
                    <Dropdown
                        options={[
                            {
                                label: "Alterar senha",
                                onClick: async () => {
                                    window.open(`${process.env.REACT_APP_AUTH_URL}/Manage/ChangePassword`);
                                },
                            },
                            {
                                label: "Sair",
                                onClick: () => {
                                    auth?.userManager.signoutRedirect();
                                },
                            },
                        ]}
                    >
                        <div className={styles.perfil}>
                            <div className={styles.user}>{String(auth?.user?.profile.name).charAt(0)}</div>
                            <DownIcon alt="Menu do perfil" />
                        </div>
                    </Dropdown>
                </div>
            </div>
        </nav>
    );
};
