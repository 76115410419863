import { ReactComponent as DownloadIcon } from "./download-icon.svg";
import React, { useMemo } from "react";
import styles from "./button-download.module.scss";

export interface ButtonDownloadProps {
    children?: any;
    hideIcon?: boolean;
    className?: string;
    fluid?: boolean;
    onClick?: () => Promise<void> | void;
}

export const ButtonDownload = (props: ButtonDownloadProps) => {
    const buttonClassName = useMemo(() => (
            [
                props.className || styles["button-download"],
                props.fluid ? styles["fluid"] : ""
            ].join(" ")
        ), [props.className, props.fluid]);

    return (
        <button 
            className={buttonClassName}
            onClick={props.onClick}
        >
            {
                props.hideIcon ?
                    null
                    :
                    <DownloadIcon className={props.className ? "" : styles["icon"]} />
            }
            {props.children}
        </button>
    );
}