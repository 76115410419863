import { useField } from "formik";
import React from "react";
import styles from "./opcao-abstencao.module.scss"

export const AbstencaoOption = (props: { nameAbstencao: string; labelAbstencao?: string }) => {
    const [field, , helpers] = useField(props.nameAbstencao);

    return (
        <div className={styles["abstencao"]}>
            <input
                {...field}
                id={props.nameAbstencao}
                name={props.nameAbstencao}
                type="checkbox"
                className={styles["input"]}
                checked={field.value}
                onChange={v => helpers.setValue(v.target.checked)}
            />
            <label 
                htmlFor={props.nameAbstencao}
                className={styles["label"]}
            >
                {props.labelAbstencao || "Não definir valor"}
            </label>
        </div>
    );
};