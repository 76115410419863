import { createContext } from "react";

export interface InfoParametroCombo {
    label?: string;
    idValue?: Record<string | number, string>;
    format?: (value: any) => string;
}
export type ValoresCombos = Record<string, InfoParametroCombo>;

export interface ValoresComboContextParams {
    valoresCombos: ValoresCombos;
    setValoresCombos: (v: ValoresCombos) => void;
}

export const ValoresComboContext = createContext<ValoresComboContextParams>({
    valoresCombos: {},
    setValoresCombos: () => {},
});
